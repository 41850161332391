<header class="header-global">
   <nav id="navbar-main" class="navbar navbar-main fixed-top navbar-expand-lg navbar-transparent navbar-light headroom">
      <div class="container">
         <div id="logo">
            <a [routerLink]="['/home']"><img class="logo" src="assets/images/logo-my-package.png" alt=""></a>
         </div>
         <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global"
            aria-expanded="false" aria-label="Toggle navigation">
         <span class="navbar-toggler-icon"></span>
         </button> -->
         <div class="navbar-collapse collapse" id="navbar_global">
            <!-- <div class="navbar-collapse-header"> 
               <div class="row">
                  <div class="col-6 collapse-brand">
                     <div id="logo">
                        <a [routerLink]="['/home']"><img class="img-fluid" src="assets/images/logo-my-package.png" width="164" height="25" alt=""></a>
                     </div>
                  </div> 
                  <div class="col-6 collapse-close">
                     <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbar_global"
                        aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation">
                     <span></span>
                     <span></span>
                     </button>
                  </div>
                </div>
             </div> -->
            <div class="navbar-nav navbar-nav-hover align-items-lg-center ml-auto">
               <!-- <ul class="m-0 p-0">
                  <app-menu></app-menu>
               </ul> -->
               <div class="header-widget">
                  <a (click)="makeCall('+972502144773')" class="btn btn-neutral btn-icon btn-radius"><i class="fa fa-phone"></i>  050-214-4773</a>
               </div>
            </div>
         </div>
      </div>
   </nav>
</header>

