<div class="block-space bg-gradient-warning">
	<div class="container">
		<div class="row row-grid align-items-center">
			<div class="col-lg-12 order-lg-1">
				<div class="d-flex px-3">
					<div>
						<div class="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
							<i class="fa fa-smile-o text-primary"></i>
						</div>
					</div>
					<div class="pl-4">
						<h4 class="display-3 text-white">{{Title}}</h4>
						<p class="text-white">{{Desc}}</p>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-6" *ngFor="let feature of Data">
						<div class="card shadow shadow-lg--hover mt-5">
							<div class="card-body">
								<div class="d-flex px-3">
									<div>
										<div class="icon icon-shape bg-gradient-warning rounded-circle text-white">
											<i class="{{feature.icon}}"></i>
										</div>
									</div>
									<div class="pl-4">
										<h5 class="title text-warning">{{feature.name}}</h5>
										<p>{{feature.desc}}</p>
										<a class="text-warning">Learn more</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- SVG separator -->
	<div class="separator separator-bottom separator-skew zindex-100">
		<svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
			<polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
		</svg>
	</div>
</div>