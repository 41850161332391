<div class="position-relative">
   <!-- Hero for FREE version -->
   <section class="section section-lg section-hero section-shaped">
      <!-- Background circles -->
      <div class="shape shape-style-1 shape-primary">
         <span class="span-150"></span>
         <span class="span-50"></span>
         <span class="span-50"></span>
         <span class="span-75"></span>
         <span class="span-100"></span>
         <span class="span-75"></span>
         <span class="span-50"></span>
         <span class="span-100"></span>
         <span class="span-50"></span>
         <span class="span-100"></span>
         <div class="overlay-bg"></div>
      </div>
      <div class="container shape-container d-flex align-items-center py-lg">
         <div class="main-search-inner">
            <h2 class="text-white display-2">{{Title}}</h2>
            <h4 class="text-white">{{Desc}}</h4>
            <div class="main-search-input">
               <div class="main-search-input-item">
                  <input class="category-banner-select-text" type="text" placeholder="תביא לי מהחנות של" value="" [(ngModel)]="shopName"/>
               </div>
               <div class="main-search-input-item location">
                  <div id="autocomplete-container">
                     <input id="autocomplete-input" class="category-banner-select-text" type="text" placeholder="או, פשוט חפש לי באזור" [(ngModel)]="address">
                     <i class="fa fa-map-marker category-banner-select-text"></i>
                  </div>
               </div>
               <div class="main-search-input-item">
                  <select data-placeholder=":בחנויות של" class="chosen-select custom-select category-banner-select-text" [(ngModel)]="category">
                     <option value=":בחנויות של" selected="true">בחנויות של</option>
                     <option *ngFor="let category of categories" [value]="category.title">{{category.title}}</option>
                  </select>
               </div>
               <a href="#" class="btn main-search-btn btn-radius btn-lg btn-white" (click)="search($event)">
               <span class="btn-inner--text">חפש לי</span>
               </a>
            </div>
         </div>
      </div>
      <!-- SVG separator -->
      <div class="separator separator-bottom separator-skew zindex-100">
         <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
         </svg>
      </div>
   </section>
</div>

