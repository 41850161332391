<nav id="navigation" class="style-1">
   <ul id="responsive">
      <li *ngFor="let menuitem of menuItems.getAll()" [ngClass]="{'open-menu': selectedMenu == menuitem.name}">
         <a class="current" [routerLink]="['/', menuitem.state]" *ngIf="menuitem.type === 'link'">{{ menuitem.name }}</a>
         <a class="current" *ngIf="menuitem.type === 'sub'" (click)="menuClick(menuitem.name)">{{ menuitem.name }}</a>
         <ul *ngIf="menuitem.type === 'sub'">
            <li *ngFor="let childitem of menuitem.children" [ngClass]="{'open-menu': selectedSubMenu == childitem.name}">
               <a [routerLink]="['/', menuitem.state, childitem.state ]" *ngIf="childitem.type === 'link'">{{ childitem.name }}</a>
               <a *ngIf="childitem.type === 'sub'" (click)="subMenuClick(childitem.name)">{{ childitem.name }}</a>
               <ul *ngIf="childitem.type === 'sub'">
                  <li *ngFor="let subchilditem of childitem.children">
                     <a [routerLink]="['/', menuitem.state, childitem.state, subchilditem.state ]">{{ subchilditem.name }}</a>
                  </li>
               </ul>
            </li>
         </ul>
      </li>
   </ul>
</nav>