import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'parallax-section',
  templateUrl: './Parallax.component.html',
  styleUrls: ['./Parallax.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ParallaxComponent implements OnInit {

   constructor(){}

   ngOnInit(){}

   ngAfterViewInit()
   {
   }

}
