import { Injectable } from '@angular/core';
import { Place } from '../models/place';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  userLocation:any|undefined;
  constructor() { }

  getUserLocation() {
    if (navigator.geolocation && !this.userLocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.userLocation = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        };
      }, (error) => {
        console.error('Error getting user location:', error);
      });
    } else if(!this.userLocation){
      console.error('Geolocation is not supported by this browser.');
    }
  }

  calculateDistances(data:Place[]) {
    data.forEach(address => {
      address.distance = this.calculateDistance(
        this.userLocation.latitude,
        this.userLocation.longitude,
        address.lat,
        address.lng
      );
    });

    // Sort addresses by distance
    var sortedData = data.sort((a, b) => a.distance - b.distance);
    return sortedData;
  }

  calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    // Implement Haversine formula or use a library for more accurate distance calculation
    // This is a simplified example using a straight-line distance formula
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = this.deg2rad(lat2 - lat1);
    const dLon = this.deg2rad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // Distance in kilometers

    return distance;
  }

  deg2rad(deg: number): number {
    return deg * (Math.PI / 180);
  }
}
