<div class="row">
 <div class="col-md-12">
	 <!-- Pagination -->
	 <div class="mt-3">
		 <nav aria-label="Page navigation">
			 <ul class="pagination">
				 <li class="page-item">
					 <a class="page-link"><i class="fa fa-angle-left"></i></a>
				 </li>
				 <li class="page-item">
					 <a class="page-link">1</a>
				 </li>
				 <li class="page-item active">
					 <a class="page-link">2</a>
				 </li>
				 <li class="page-item">
					 <a class="page-link">3</a>
				 </li>
				 <li class="page-item">
					 <a class="page-link">4</a>
				 </li>
				 <li class="page-item">
					 <a class="page-link">5</a>
				 </li>
				 <li class="page-item">
					 <a class="page-link"><i class="fa fa-angle-right"></i></a>
				 </li>
			 </ul>
		 </nav>
	 </div>
 </div>
</div>