import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { ShopService } from '../../shared/services/shop.service';
import { SearchLogService } from '../../shared/services/search-log.service';
import { LocationService } from '../../shared/services/location-service.service';
import { Place } from 'src/app/shared/models/place';
import { translations } from '../../shared/translations/translations';
import { SearchLog } from 'src/app/shared/models/searchLog';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'list-with-sidebar',
  templateUrl: './ListWithSidebar.component.html',
  styleUrls: ['./ListWithSidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListWithSidebarComponent implements OnInit{

   Data : any[] = [];
   userLocation:any|undefined;
   bannerDesc1 : string = 'החבילה שלך מחכה לך בחנות השכונתית? מיי';
   bannerDesc2 :string = '\u05E4\u05BC';
   bannerDesc3 :string = 'אק פשוט תאסוף לך את החבילה או סתם קנייה קטנה מהחנות השכונתית, יחד מחזקים את הקהילה המקומית!';
   public translations:any;
   constructor(private shopService:ShopService
              ,private logService:SearchLogService
              ,private inAppBrowser: InAppBrowser,
              private locationService: LocationService){}

   ngOnInit(){
      this.Data = this.shopService.Shops;
      this.locationService.getUserLocation();
      this.translations = translations;
   }

   ngAfterViewInit()
   {

   }

   getUserLocation() {
      if (navigator.geolocation && !this.locationService.userLocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.userLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          };
          let log = new SearchLog();
          log.search_field = this.logService.searchField;
          log.zero_results = this.Data.length ? 1 : 0;
          log.search_lat = this.userLocation.latitude;
          log.search_lng = this.userLocation.longitude;
          this.logService.CreateSearchlog(log);
          // Calculate distances and sort addresses
          this.Data = this.locationService.calculateDistances(this.Data);
        }, (error) => {
          console.error('Error getting user location:', error);
        });
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    }

    openInAppBrowser(url: string): void {
      const browser = this.inAppBrowser.create(url, '_blank');
    }

}
