<div class="parallax"
   data-background="assets/images/slider-parallax.jpg">
   <!-- Infobox -->
   <div class="text-content white-font block-space">
      <div class="container">

         <div class="row">
            <div class="col-lg-6 col-sm-8">
               <h2>Streamline Your Business</h2>
               <p>We’re full-service, local agents who know how to find people and home each together. We use online tools with an unmatched search capability to make you smarter and faster.</p>
               <a [routerLink]="['/listing/list/with-sidebar']" class="btn btn-primary">Get Started</a>
            </div>
         </div>
      </div>
   </div>
   <!-- Infobox / End -->
</div>