

<div class="block-space">
   <!-- Container / Start -->
   <div class="container">
      <div class="block-head text-center ">
         <h2 class="head-line display-3 mb-5" > Get an Exposure For Your Listing </h2>
      </div>
      <!-- Row / Start -->
      <div class="row pt-4">
         <div class="col-md-12">
            <div class="pricing-container margin-top-30">
               <!-- Plan #1 -->
               <div class="plan">
                  <div class="plan-price">
                     <h3>Basic</h3>
                     <span class="value">Free</span>
                     <span class="period">Free of charge one standard listing active for 30 days</span>
                  </div>
                  <div class="plan-features">
                     <ul>
                        <li>One Listing</li>
                        <li>30 Days Availability</li>
                        <li>Standard Listing</li>
                        <li>Limited Support</li>
                     </ul>
                     <a href="#" class="btn btn-1 btn-outline-primary btn-radius">Get Started</a>
                  </div>
               </div>
               <!-- Plan #3 -->
               <div class="plan featured">
                  <div class="listing-badge">
                     <span class="featured">Featured</span>
                  </div>
                  <div class="plan-price">
                     <h3>Extended</h3>
                     <span class="value">$9.99</span>
                     <span class="period">One time fee for one listing, highlighted in the search results</span>
                  </div>
                  <div class="plan-features">
                     <ul>
                        <li>One Time Fee</li>
                        <li>One Listing</li>
                        <li>90 Days Availability</li>
                        <li>Featured In Search Results</li>
                        <li>24/7 Support</li>
                     </ul>
                     <a class="btn btn-primary btn-radius" href="#">Get Started</a>
                  </div>
               </div>
               <!-- Plan #3 -->
               <div class="plan">
                  <div class="plan-price">
                     <h3>Professional</h3>
                     <span class="value">$19.99</span>
                     <span class="period">Monthly subscription for unlimited listings and availability</span>
                  </div>
                  <div class="plan-features">
                     <ul>
                        <li>Unlimited Listings</li>
                        <li>Unlimited Availability</li>
                        <li>Featured In Search Results</li>
                        <li>24/7 Support</li>
                     </ul>
                     <a href="#" class="btn btn-1 btn-outline-primary btn-radius">Get Started</a>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- Row / End -->
   </div>
   <!-- Container / End -->
</div>
         
         