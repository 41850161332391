import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Shop } from '../models/shop';
import { Place } from '../models/place';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  url=environment.base_url+"shop/get_shops_list";
  public Shops : Place[] = [];
  public MostVisitedPlaces : Place[] = [];
  constructor(public http:HttpClient) { }
  
  getShops(): Observable<Shop[]>{  
    return this.http.get<Shop[]>(this.url);
  }
}
