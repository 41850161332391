import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'pricing',
  templateUrl: './Pricing.component.html',
  styleUrls: ['./Pricing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PricingComponent implements OnInit {

   constructor(){}

   ngOnInit(){}

   ngAfterViewInit()
   {

   }
}
