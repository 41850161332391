<div id="footer" class="sticky-footer pt-5">
   <!-- Main -->
   <div class="container">
      <div class="row">
         <div class="col-md-12 col-sm-12 footer-text">
            <img class="footer-logo" src="assets/images/logo-my-package-black.png" alt="">
            <br><br>
            <p class="text-primary">מחברים לקוחות לעסקים מקומיים - יחד יוצרים קהילה חזקה, מתמוך בכלכלה מקומית ומזמינים תרומה משמעותית לחיי השכונה</p>
            <div class="text-widget text-primary">
               <span class="text-primary">מייפּאק - מגדלי רסיטל, בגין 156 תל אביב</span> <br>
               <span class="text-primary">טלפון: 050-214-4773</span><br>
               <!-- E-Mail:<span class="text-primary"> <a href="#" class="text-primary">office@example.com</a> </span><br> -->
            </div>
            <div class="mt-4">
               <a target="_blank" href="https://twitter.com/" class="btn btn-neutral btn-icon-only btn-round btn-lg" data-toggle="tooltip" data-original-title="Follow us">
               <i class="fa fa-twitter"></i>
               </a>
               <a target="_blank" href="https://www.facebook.com/" class="btn btn-neutral btn-icon-only btn-round btn-lg" data-toggle="tooltip" data-original-title="Like us">
               <i class="fa fa-facebook-square"></i>
               </a>
               <a target="_blank" href="https://dribbble.com/" class="btn btn-neutral btn-icon-only  btn-lg btn-round" data-toggle="tooltip" data-original-title="Follow us">
               <i class="fa fa-dribbble"></i>
               </a>
               <a target="_blank" href="https://github.com/" class="btn btn-neutral btn-icon-only btn-round btn-lg" data-toggle="tooltip" data-original-title="Star on Github">
               <i class="fa fa-github"></i>
               </a>
            </div>
         </div>
         <!-- <div class="col-md-4 col-sm-6 ">
            <ul class="footer-links">
               <li><a class="text-primary" routerLink='/session/login'>Login</a></li>
               <li><a class="text-primary" routerLink='/session/signup'>Sign Up</a></li>
               <li><a class="text-primary" routerLink='/session/forgot-password'>Forgot Password</a></li>
               <li><a class="text-primary" routerLink='/pages/add-listing'>Add Listing</a></li>
               <li><a class="text-primary" routerLink='/pages/pricing'>Pricing</a></li>
               <li><a class="text-primary" routerLink='/pages/about-us'>About</a></li>
            </ul>
         </div> -->
         <!-- <div class="col-md-3  col-sm-12">
            <ul class="footer-links">
               <li><a class="text-primary" routerLink='/pages/user-profile'>Profile</a></li>
               <li><a class="text-primary" routerLink='/pages/blog/list'>Blog</a></li>
               <li><a class="text-primary" routerLink='/pages/invoice'>Invoice</a></li>
               <li><a class="text-primary" routerLink='/pages/booking'>Booking</a></li>
               <li><a class="text-primary" routerLink='/pages/contact-us'>Contact</a></li>
            </ul>
         </div> -->
      </div>
      <!-- Copyright -->
      <div class="row">
         <div class="col-md-12">
            <div class="copyrights text-primary">© 2024 myPackage. כל הזכויות שמורות</div>
         </div>
      </div>
   </div>
</div>

