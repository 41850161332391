<map-banner></map-banner>
<!-- Content -->
<div class="content">
   <div class="container">
      <div class="row">
         <div class="col-md-12">
            <div class="row">
               <!-- Listing Item -->
               <div class="col-lg-12 col-md-12" *ngFor="let list of Data">
                  <div class="listing-item-container list-layout mb-4">
                     <div [routerLink]="['/listing/detail/version2']" class="listing-item">
                        <!-- Image -->
                        <div class="listing-item-image">
                           <img src="{{list.image}}" alt="">
                        </div>
                        <!-- Content -->
                        <div class="listing-item-content">
                           <span class="badge badge-pill list-banner badge-success text-uppercase">{{list.badge}}</span>
                           <div class="listing-item-inner">
                              <DirectlistRating [rate]="list.rating"></DirectlistRating>
                              <h3> <a href="#"> {{list.title}}</a></h3>
                              <span><small>{{list.address}}</small></span>
                              <div class="mt-3"><span class="badge badge-pill badge-primary text-uppercase">{{list.category}}</span></div>
                           </div>
                           <span class="round-pill like-banner d-block bg-primary"><i class="fa fa-heart-o"></i></span>
                        </div>
                     </div>
                  </div>
               </div>
               <!-- Listing Item / End -->
            </div>
            <!-- Pagination -->
            <div class="clearfix"></div>
            <div class="row">
               <div class="col-md-12">
                  <!-- Pagination -->
                  <pagination></pagination>
               </div>
            </div>
            <!-- Pagination / End -->
         </div>
      </div>
   </div>
</div>

