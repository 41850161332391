<div class="col-lg-4 col-md-4 margin-top-75 sticky">
   <!-- Verified Badge -->
   <div class="verified-badge with-tip" data-tip-content="Listing has been verified and belongs the business owner or manager.">
      <i class="sl sl-icon-check"></i> Verified Listing
   </div>
   <!-- Book Now -->
   <div class="boxed-widget booking-widget margin-top-35">
      <h3>
         <i class="fa fa-calendar-check-o "></i> Booking 
         <div class="listing-price">$49 <i>/night</i></div>
      </h3>
      <div class="row with-forms  margin-top-0">
         <!-- Date Range Picker - docs: http://www.daterangepicker.com/ -->
         <div class="col-lg-12">
            <input type="text" id="date-picker" placeholder="Date" readonly="readonly">
         </div>
         <!-- Panel Dropdown -->
         <div class="col-lg-12">
            <div class="panel-dropdown">
               <a >Guests <span class="qtyTotal" name="qtyTotal">1</span></a>
               <div class="panel-dropdown-content">
                  <!-- Quantity Buttons -->
                  <div class="qtyButtons">
                     <div class="qtyTitle">Adults</div>
                     <input type="text" name="qtyInput" value="1">
                  </div>
                  <div class="qtyButtons">
                     <div class="qtyTitle">Childrens</div>
                     <input type="text" name="qtyInput" value="0">
                  </div>
               </div>
            </div>
         </div>
         <!-- Panel Dropdown / End -->
      </div>
      <!-- Book Now -->
      <a [routerLink]="['/pages/booking']" class="button book-now fullwidth margin-top-5">Request To Book</a>
   </div>
   <!-- Book Now / End -->
   <!-- Contact -->
   <div class="boxed-widget margin-top-35">
      <div class="hosted-by-title">
         <h4><span>Hosted by</span> <a href="pages-user-profile.html">Tom Perrin</a></h4>
         <a class="hosted-by-avatar"><img src="assets/images/thumb-4.jpg" alt=""></a>
      </div>
      <ul class="listing-details-sidebar">
         <li><i class="sl sl-icon-phone"></i> (123) 123-456</li>
         <li><i class="fa fa-envelope-o"></i> <a >tom@example.com</a></li>
      </ul>
      <ul class="listing-details-sidebar social-profiles">
         <li><a class="facebook-profile"><i class="fa fa-facebook-square"></i> Facebook</a></li>
         <li><a class="twitter-profile"><i class="fa fa-twitter"></i> Twitter</a></li>
      </ul>
      <!-- Reply to review popup -->
      <div id="small-dialog" class="zoom-anim-dialog mfp-hide">
         <div class="small-dialog-header">
            <h3>Send Message</h3>
         </div>
         <div class="message-reply margin-top-0">
            <textarea cols="40" rows="3" placeholder="Your message to Tom"></textarea>
            <button class="button">Send Message</button>
         </div>
      </div>
      <a class="send-message-to-owner button popup-with-zoom-anim"><i class="sl sl-icon-envelope-open"></i> Send Message</a>
   </div>
   <!-- Contact / End-->
   <!-- Share / Like -->
   <div class="listing-share margin-top-40 margin-bottom-40 no-border">
      <button class="like-button"><span class="like-icon"></span> Bookmark this listing</button> 
      <span>159 people bookmarked this place</span>
      <!-- Share Buttons -->
      <ul class="share-buttons margin-top-40 margin-bottom-0">
         <li><a class="fb-share"><i class="fa fa-facebook"></i> Share</a></li>
         <li><a class="twitter-share"><i class="fa fa-twitter"></i> Tweet</a></li>
         <li><a class="gplus-share"><i class="fa fa-google-plus"></i> Share</a></li>
      </ul>
      <div class="clearfix"></div>
   </div>
</div>

