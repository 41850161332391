<div class="block-space bg-secondary">
		  <div class="block-head text-center mb-5">
					 <h2 class="head-line display-3">
						  {{Title}}
					 </h2>
					 <p class="lead mt-2 head-desc text-primary">Hassel Free Service</p>
				</div>
		  <div class="container">
			 <div class="row justify-content-center">
				<div class="col-lg-12">
				  <div class="row row-grid">
					 <div class="col-lg-4" *ngFor="let blog of Data">
						<div class="card card-lift--hover shadow border-0">
						  <img class="img-fluid" src="{{blog.image}}" alt="">
						  <div class="card-body py-4">
							 <h5>{{blog.title}}</h5>
							 <p class="text-muted">{{blog.date}}</p>
							 <!-- <div>
								  <span class="badge badge-pill badge-primary mr-1">{{blog.tag}}</span>
								</div> -->
							 <p class="description mb-4">{{blog.desc}}</p>
							 <a routerLink='/pages/blog/detail' class="btn btn-primary">Read More</a>
						  </div>
						</div>
					 </div>
				  </div>
				</div>
			 </div>
		  </div>
	 </div>


