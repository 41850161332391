import { Component, OnInit, Input, Output, AfterViewInit, ViewEncapsulation, ChangeDetectionStrategy, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Place } from 'src/app/shared/models/place';
import { LocationService } from 'src/app/shared/services/location-service.service';
import { ShopService } from 'src/app/shared/services/shop.service';

@Component({
  selector: 'most-visited-places',
  templateUrl: './MostVisitedPlaces.component.html',
  styleUrls: ['./MostVisitedPlaces.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MostVisitedPlacesComponent implements OnInit {

   /** Title for baner **/
   @Input('title') Title: any = 'Dummy Title';

   /** Description for baner **/
   @Input('desc') Desc: any = 'Description';

   /** Description for baner **/
   @Input('bgColor') BgColor: any = '#f8f8f8';

   /** Background for baner **/
   @Input('data') Data: any;
   

   slideConfig = {
                  infinite: true,
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  dots: true,
                  arrows: true,
                  responsive: [
                      {
                        breakpoint: 992,
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 2
                        }
                      },
                      {
                        breakpoint: 769,
                        settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1
                        }
                      }
                 ]
               };

   constructor(public ShopService:ShopService
    ,private inAppBrowser: InAppBrowser){

   }

   ngOnInit(){
      console.log(this.Data);
   }

   openInAppBrowser(url: string): void {
    const browser = this.inAppBrowser.create(url, '_blank');
  }
}
