import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sidebar-layout-three',
  templateUrl: './SidebarLayoutThree.component.html',
  styleUrls: ['./SidebarLayoutThree.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarLayoutThreeComponent implements OnInit{

   constructor(){}

   ngOnInit(){}

   ngAfterViewInit()
   {

   }
}
