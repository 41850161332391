import { Component, OnInit, Input, Output, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { Category } from '../../shared/models/category';
import { ShopService } from '../../shared/services/shop.service';
import { Router,ActivatedRoute  } from '@angular/router';
import { Shop } from 'src/app/shared/models/shop';
import { Place } from 'src/app/shared/models/place';
import { SearchLogService } from 'src/app/shared/services/search-log.service';

@Component({
  selector: 'banner-version1',
  templateUrl: './Banner.component.html',
  styleUrls: ['./Banner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BannerComponent implements OnInit {
  shopName : string = '';
  address : string|undefined;
  category:string = ":בחנויות של";
   /** Title for baner **/
   @Input('title') Title: any = 'Dummy Title';

   /** Description for baner **/
   @Input('desc') Desc: any = 'Description';

   /** Background for baner **/
   @Input('bgImageUrl') BgImageUrl: any = 'assets/images/bg-main-my-package.jpeg';

   @Input('categories') categories: any = [];

   constructor(private router:Router
      ,private shopService:ShopService
      ,private logService:SearchLogService){}

   ngOnInit(){
    console.log(this.categories);
   }

   ngAfterViewInit()
   {

   }

   search(e:Event){
    e.preventDefault();
    this.logService.searchField = this.shopName ? this.shopName : (this.address ? this.address : this.category); 
    this.shopService.Shops = this.shopService.Shops.filter((shop:Place)=>{
      return (shop.category == this.category || this.category == ":בחנויות של") 
        && (this.shopName ? shop.title == this.shopName : true)
        && (this.address&&shop.address ? shop.address.includes(this.address) : true);
    });
    this.router.navigate(['listing/list/with-sidebar']);
   }
}
