<div id="titlebar" class="gradient">
   <div class="title-bar-inner">
      <div class="container">
         <div class="row">
            <div class="col-md-12 align-titles">
               <h2>{{Title}}</h2>
               <span>{{SubTitle}}</span>
               <!-- Breadcrumbs -->
               <!-- <nav id="breadcrumbs">
                  <ul>
                     <li><a [routerLink]="['/home']">MyPackage</a></li>
                     <li>חיפוש</li>
                  </ul>
               </nav> -->
            </div>
         </div>
      </div>
   </div>
   <div class="title-overlay"></div>
</div>
